@font-face {
  font-family: "Montserrat";
  src: local("AirbnbCerealLight"),
    url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: local("AirbnbCerealLight"),
  url("./fonts/Gilroy/Gilroy-Regular.ttf")
  format("truetype");
}

body {
  margin: 0;
  height: 100%;
  font-family: "Gilroy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

textarea {
  font-family: "Gilroy";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
